@import 'utils/colors.scss';
@import 'utils/sizes.scss';

.SubmitOrderButton {
    background-color: $default-red;
    color: $text-primary;
    height: 39px;
    border-radius: 15px;

    width: 90%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    @media (max-width: $breakpoint-sm) {
        flex-direction: column;
        gap: 0px;
        height: max-content;
        padding: 5px;
        line-height: 1.2;
        border-radius: 17px;
    }

    &__Price {
        color: $text-light-gray;
    }
}

.AddCloseBar {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}