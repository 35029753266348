@import 'utils/colors.scss';
@import 'utils/sizes.scss';

.AddToBasketButton {
    background-color: $default-red;
    color: $text-primary;
    height: 42px;
    width: 158px;
    border: none;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    gap:5px;
    justify-content: center;
    align-items: center;

    @media (max-width: $breakpoint-md) {
        width: 50px;
        align-self: end;
    }
}