@import 'utils/sizes.scss';

.AboutUsText {
    display: flex;
    flex-direction: column;
    gap:30px;

    &__Button {
        margin-top: 2vh;
    }
}

.MaxWidth {
    width: 500px;
}

.Paragraph {
    @media (max-width: $breakpoint-sm) {
        width: inherit;
    }
}