@import 'utils/colors.scss';

.Link {
    text-decoration: none;
    color: $text-primary !important;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
  
    &:visited {
      color: inherit;
    }
  
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      color: $text-light-gray !important;
      outline: none;
    }
  
    &:focus-visible {
      outline: none;
    }
  }