@import 'utils/sizes.scss';

.MenuSection {
    height: 100%;
    width: 70vw;
    display: flex;
    flex-direction: column;
    text-align: center;

    
    @media (max-width: $breakpoint-md) {
        width: 80vw;
    }
} 