@import 'utils/colors.scss';

.AddToOrderButton {
    background-color: $default-red;
    color: $text-primary;
    height: 39px;
    border-radius: 15px;
    cursor: pointer;

    width: 90%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    &__Price {
        color: $text-light-gray;
    }
}