.Radios {
  padding: 10px 0px;

  &__RadioList {
    margin-bottom: 24px;
    margin-top: 5px;
  }

  &__Radio {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 3px 0px;
    padding: 3px; 
  }

  &__Paragraph {
    margin-left: auto;
    margin-right: 0px;
    text-wrap: nowrap;
  }
}
