@import 'utils/sizes.scss';

.AboutUsSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%; 
    flex-wrap: wrap;
    gap:2%;

    > * { 
        flex-basis: 40%; /* Basis size */
        flex-shrink: 1;  /* Allow shrinking */
    }

    @media (max-width: $breakpoint-bg) {
        margin-top: 10%;
        margin-bottom: 20%;
    }

    @media (max-width: $breakpoint-sm) {
        margin-top: 20%;
        margin-bottom: 30%;

        max-width: 90% !important;

        > * { 
            flex-basis: 100%;
        }
    }
} 