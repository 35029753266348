.NotFound {
    width: 100%;
    height: 100vh;
    background-color: azure;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    &__Paragraph {
        color: #000000;
        padding-top: 0px;
    }
}

.Icon {
    font-size: 30px;
    cursor: pointer;
}