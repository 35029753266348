.FullPage {
    min-height:100vh;
    height: auto;
    width:100%;
    background: 
    linear-gradient(rgba(11, 8, 6, 0.8), rgba(11, 8, 6, 0.8)),
    url('~assets/photos/bg/bg-texture.png'); 
    background-position: center;
    background-blend-mode: overlay; 
    overflow: visible;

    display: flex;
    justify-content: center;
    padding-top: 4vh;
    padding-bottom: 10vh;
} 

.AllPoints {
    width: 80%;
    margin-top: 20vh;

    span {
        display: block;
        padding-top: 30px;
        margin-top: 30px;
    }
}