@import 'utils/colors.scss';

.CommentBox {
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__TextArea {
        overflow: hidden; 
        resize: none; 

        width: calc(100% - 20px);
        min-height: 50px; 
        max-height: 200px; 
        line-height: 1.5; 

        padding: 5px 10px;
        border: 1px solid $text-light-gray;
        background-color: transparent;
        color: $text-primary;

        &:focus {
            outline: none;
            border: 1px solid $text-primary;
        }
    }
}