@import 'utils/colors.scss';

.DeliveryTypeSelector {
    width: 100%;
    cursor: pointer;
    border-radius: 15px;
    border: 1px solid $text-light-gray;
    background-color: $light-black;
    padding:5px;
    margin: 20px 0px;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    text-align: center;
    box-sizing: border-box;

    > * {
        width: 100%;
    }

    &__ActiveSingle {
        border-top-right-radius: 11px !important;
        border-bottom-right-radius: 11px !important;
        cursor: default;
    }

    &__ActiveRight {
        background-color: $default-red !important;
        border-radius: 11px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        color: $text-primary !important;
    }

    &__ActiveLeft {
        background-color: $default-red !important;
        border-radius: 11px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        color: $text-primary !important;
    }
}

.Paragraph {
    color: $text-light-gray !important;
}