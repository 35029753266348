@import 'utils/colors.scss';
@import 'utils/sizes.scss';

.BaseModal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 4000;

    display: flex;
    justify-content: center;
    align-items: center;

    &__Modal {
        min-height: 400px;
        height: auto;
        max-height: 80vh;
        width: 500px;
        padding-bottom: 10px;
        background-color: $light-black;
        border-radius: 15px;
        overflow: scroll;

        @media (max-width: $breakpoint-sm) {
            height: 100%;
            max-height: 100%;
        }
    }
}