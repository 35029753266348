.LoadingOverlay {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100000;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: rgba(2, 2, 2, 0.2);
    backdrop-filter: blur(2.9px);
    -webkit-backdrop-filter: blur(2.9px);
}