@import 'utils/colors.scss';

.CircledIcon {
    border-radius: 50%;
    // border: 2px solid $text-primary;
    // color: $text-light-gray;
    border: 2px solid $text-dark-gray;
    color: $text-primary;
    padding: 5px;
    font-size: 30px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}