.footer {
    width: 200px;
    height: 200px
}

.logo {
    background: url("../../../assets/logo/logo.png") no-repeat;
    background-size: 100% auto;
    background-position: center; 
    width: 200px;
    height: 200px;
  }

  @media (prefers-reduced-motion: no-preference) {
    .logo {
      animation: App-logo-spin infinite 20s linear;
    }
}

@keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  