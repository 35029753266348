@import 'utils/colors.scss';

.PickupSelection {
    display: flex;
    flex-direction: column;
    gap: 5px;

    &__ExtraPadding {
        padding: 10px 0px;
        
        > * {
            margin: 5px 0px;
        }
    }
}

.Paragraph {
    color: $light-gray;
}