@import 'utils/colors.scss';

.Page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 10vh;

    &__Paragraph {
        padding-top: 5vh;
    }

    &__Link {
        color: $link;

        &:visited, &:active {
            color: $link-visited;
        }
    }
}