.AddOns {
    padding: 10px 0px;
    &__CheckBox {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    &__Check {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 7px;
    }
}
