@import 'utils/sizes.scss';

.header {
    width: 200px;
    height: 15vh;
    position: fixed;
    top: 0;
    left: 0;
    transition: height 500ms;
    padding-top: 20px;
    padding-left: 3%;
    display: flex;
    justify-content: flex-start; 
    align-items: center; 

     @media (max-width: $breakpoint-sm) {
      padding-top: 0px;
    }
  
    .logo {
      background: url("../../assets/logo/logo.png") no-repeat;
      background-size: 100% auto;
      background-position: center; 
      width: 200px;
      height: 200px;
      transition: all 0.4s ease-in-out 0s;

      @media (max-width: $breakpoint-sm) {
        // width: 140px;
        height: 15vh;
        width: 15vh ;
    }
    }
  
    &.tiny {
        height: 12vh;
        padding-top:0px;

      .logo {
        background: url("../../assets/logo/logo.png") no-repeat !important;
        background-size: 100% !important;
        background-position: center !important; 
        width: 120px !important;
        height: 120px !important;
        transition: all 0.4s ease-in-out 0s;

        @media (max-width: $breakpoint-sm) {
          // width: 100px !important;
          height: 10vh !important;
          width: 10vh !important;
      }
      }
    }
  }