@import 'utils/colors.scss';
@import 'utils/sizes.scss';

.AddItemModal {
    overflow: hidden !important;
    hr {
        margin: 0;
        border: 3px solid $dark-gray;
    }

    &__Layout {
        display: flex;
        flex-direction: column;
        height: 80vh;
        overflow: hidden;

        @media (max-width: $breakpoint-sm) {
            height: 100dvh;
        }
    }

    &__ScrollableContainer {
        overflow: scroll;
    }

    &__FixedContainer {
        align-content: center;
        height: 170px;
        box-shadow: 0 -1px 4px 0 rgba(255,255,255,.1);

        @media (max-width: $breakpoint-md) {
            height: 200px;
        }
    }

    &__Image {
        min-height: 350px;
        height:40%;
        width: inherit;
        overflow: hidden;
    }

    &__Section {
        padding: 20px 30px;
        text-align: left;

        > * {
            margin-bottom: 5px;
        }
    }
 
    &__CloseButtonRow {
        position: absolute;
        width: 100%;
        max-width: 500px;
        background-color: rgb(0, 0, 0, 0.7);
        border-top-left-radius: 15px; 
        border-top-right-radius: 15px;
        text-align: right;
        padding-top: 10px;
    }

    &__CloseButton {
        margin-right: 10px;
    }
}

.CloseButton {
    display: block;
    position: fixed;
    top: 3%;
    right: 5%;
    color: $light-black !important;
    overflow: hidden;
}
