@import 'utils/colors.scss';

.BaseSection {
    min-height:100vh;
    height: auto;
    width:100%;
    background: 
    linear-gradient(rgba(11, 8, 6, 0.8), rgba(11, 8, 6, 0.8)),
    url('~assets/photos/bg/bg-texture.png'); 
    background-size: cover; 
    background-position: center;
    background-blend-mode: overlay; 
    overflow: visible;

    display: flex;
    justify-content: center;
    padding: 4vh 0;
} 

.LowerHeight {
    min-height: 60vh !important;
}
