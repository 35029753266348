@import 'utils/sizes.scss';

.OurInfo {
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    align-items: flex-start;
    padding: 60px 30px;

    > * {
        padding: 20px;
    }

    @media (max-width: $breakpoint-bg) {
        display: grid;
        grid-template-columns: repeat(2, [col] auto ) ;
        grid-template-rows: repeat(2, [row] auto );
    }

    @media (max-width: $breakpoint-sm) {
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;
    }
} 