@import 'utils/sizes.scss';
@import 'utils/colors.scss';

.Container {
    display: flex;
    align-items: center;
    justify-content: center;
}
 
.PhotoCollage {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(5, [col] 90px ) ;
    grid-template-rows: repeat(6, [row] 90px );
    gap:10px;
    color: #444;

    > * {
        background-position: center;
        background-size: cover;
    }

    @media (max-width: $breakpoint-sm) {
        grid-template-columns: repeat(5, 60px ) ;
        grid-template-rows: repeat(6, [row] 80px );
    }
}


.One {
    grid-column: 1 / span 3; /* Columns 1, 2, 3 */
    grid-row: 1 / span 3;    /* Rows 1, 2, 3 */
    border: 1px solid $text-light-gray;
    background-image: url('~assets/photos/vibe/potatoes-display.jpg');
}

.Two {
    grid-column: 4 / span 2; /* Columns 4, 5 */
    grid-row: 2 / span 2;    /* Rows 2, 3 */
    background-image: url('~assets/photos/vibe/outro-logo.png');
}

.Three {
    grid-column: 1 / span 2; /* Columns 1, 2 */
    grid-row: 4 / span 2;    /* Rows 4, 5 */
    background-image: url('~assets/photos/vibe/intro.jpeg');
}

.Four {
    grid-column: 3 / span 3; /* Columns 3, 4, 5 */
    grid-row: 4 / span 3;    /* Rows 4, 5, 6 */
    border: 1px solid $text-light-gray;
    background-image: url('~assets/photos/nachos/cheese.JPG');
}