@import 'utils/sizes.scss';

.Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-bottom: 20px;
    width: 100%;
}

.Vibe {
    display: grid;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(4, [col] auto ) ;
    grid-template-rows: repeat(2, [row] minmax(300px, 1fr) );
    gap:10px;
    color: #444;
    width: 95%;
    height: 75%;

    > * {
        background-position: center;
        background-size: cover;
    }

    @media (max-width: $breakpoint-bg) {
        grid-template-columns: repeat(2, [col] auto ) ;
        grid-template-rows: repeat(4, minmax(300px, 1fr));
        margin-bottom: 10%;
    }
}


.One {
    background-image: url('~assets/photos/vibe/intro.jpeg');
}

.Two {
    background-image: url('~assets/photos/vibe/potatoes-display.jpg');
}

.Three {
    background-image: url('~assets/photos/vibe/marynarza.png');
}

.Four {
    background-image: url('~assets/photos/vibe/iam.jpg');
}

.BigSquare {
    grid-column: 3 / span 2;
    grid-row: 1 / span 2;
    background-image: url('~assets/photos/vibe/outro-logo.png');

    
    @media (max-width: $breakpoint-bg) {
        grid-column: 1 / span 2;
        grid-row: 1 / span 2;
    }
}