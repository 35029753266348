@import 'index.css';
@import 'utils/colors.scss';

.Typography {
    color: $text-primary;

    > p, h1, h2 {
        margin: 0px;
    }
    
    &__Title {
        font-size: calc(32px + 1.5vmin);
        font-family: "Merienda", cursive;
        font-weight: 600;
        padding-bottom: 1vh;
        font-style: normal;
    }
    
    &__Heading1 {
        font-size: 22px;
        font-family: "Sarala", sans-serif;
    }
    
    &__Heading2 {
        font-size: 18px;
        font-family: "Sarala", sans-serif;
        font-weight: 400;
    }
    
    &__Heading3 {
        font-size: 16px;
        font-family: "Sarala", sans-serif;
    }
    
    &__BodyText {
        font-size: 15px;
        font-family: "Sarala", sans-serif;
    }
}

.bold {
    font-weight: 700;
}