// components
$main-page-color: #000000;
$primary-color: #0B0806;
$light-gray: #D9D9D9;
$dark-gray: #777777;
$light-black: #1A1A1A;

// text colors
$text-primary: #EAEAEA;
$text-light-gray: #B5B3B3;
$text-dark-gray: #777777;

// buttons
$default-red: #E03E3E;
$light-red: #EA7E7E;
$button-light-text: #FFFFFF;
$button-dark-text: #C6C6C6;

// others
$facebook: #316FF6;
$link: #3366CC;
$link-visited:#795CB2;