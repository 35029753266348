@import 'utils/colors.scss';

.BasketButton {
    background-color: $default-red;
    color: $text-primary;
    height: 55px;
    width: 158px;
    border: none;

    display: flex;
    flex-direction: row;
    gap:5px;
    justify-content: center;
    align-items: center;

    position: fixed;
    bottom: 5%;
    right: 3%;
    z-index: 1000;
    cursor: pointer;
}