@import 'utils/colors.scss';

.MobileTopBar {
    position: fixed;
    width: 100%;
    height: 15vh;
    z-index: 3000;

    transition: height 500ms;

    color: $text-primary;
    background-color: $main-page-color;
    font-size: 40px;
    text-align: right;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    &__Icon {
        padding-right: 5%;
        padding-top: 1vh;
    }

    &__ScrolledDown {
        height: 12vh !important;
        transition: all 0.4s ease-in-out 0s;
     }

}

.MobileSheet {
    width: 45vw;
    height: 100vh;
    background-color: $default-red;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3000;
    padding: 5%;

    &__List{
        padding: 10px 0px;
        > * {
            padding: 10px 0px;
        }
    }
}