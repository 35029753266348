@import 'utils/colors.scss';

.OpeningTimes {
    display: flex;
    flex-direction: row;
    gap:10px;

    * > p {
        text-align: left;
    }
    &__Arrow {
        font-size: 23px;
        color: $text-primary;
        cursor: pointer;
    }
}
