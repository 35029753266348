.InfoCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;

    &__Icon {
        margin-bottom: 15px;
    }
}