@import 'utils/colors.scss';
@import 'utils/sizes.scss';

// import './autocompleteStyles.css'
// const places = useMapsLibrary("places");

.InputField {
    color: white;
    padding: 5px 0px; 

        input {
            background-color: transparent;
            border: 1px solid $light-gray;
            padding: 7px 10px;
            color: $text-primary;
            width: 70%;

            &:focus-visible {
                outline: none;
            }

            @media (max-width: $breakpoint-sm) {
                width: calc(100% - 20px);
            }
        }

        &__ErrorMessage {
            color: $default-red !important;
            font-size: 12px;
        }
}