@import 'utils/colors.scss';

.Footer {
    min-height: 25vh;
    height: auto;
    background-color: $primary-color !important;
    padding: 7vh 10vw;
    background-color: $primary-color;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3vh;

    &__MainBody {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap:5vw;
    }

    &__LegalRow {
        display: flex;
        flex-direction: row;
        gap: 5px;

        span {
            cursor: pointer;

            &:hover {
                color: $text-light-gray;
            }
        }
    }    
}

.Icon {
    font-size: 36px;
    margin: 5px;
    margin-left: 0px;
    color: $text-primary;

    &:focus-visible,
    &:focus,
    &:visited,
    &:active {
        color: $text-primary;
    }

    &:hover {
        color: $facebook;
    }
}
