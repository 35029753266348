@import 'utils/colors.scss';

.TopBar {
    position: fixed;
    width: 100%;
    height: 18vh;
    transition: height 500ms;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: $main-page-color;
    z-index: 3000;

     &__MenuActions {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding-right: 5vw;

        > * {
            margin-left: calc(15px + 2vw);
        }
     }

     &__ScrolledDown {
        height: 12vh !important;
        transition: all 0.4s ease-in-out 0s;
     }
}