@import 'utils/colors.scss';

.MenuButton {
    height: 48px;
    width: 122px;
    background-color: transparent;
    border: 1px solid $default-red;
    display: flex;
    justify-content: center;
    align-items: center;

    > * {
        color: $default-red !important;
    }
}