@import 'utils/colors.scss';

.FooterMenu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.Paragraph {
    color: $text-light-gray;

    &:hover {
        color: $text-primary;
    }
}