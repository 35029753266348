@import 'utils/colors.scss';
@import 'utils/sizes.scss';

.MenuList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding-top: 20px;
    padding-bottom: 10px;
    
    border-bottom: 1px solid $dark-gray;

    @media (max-width: $breakpoint-sm) {
        flex-direction: column;
        gap: 8px;
    }

    &__Text {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        gap: 5px;
        text-align: left;
    }
}

.NoBorder {
    border: none !important;
}