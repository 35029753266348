@import 'utils/colors.scss';

.Counter {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    padding-top: 20px;
}

.Active {
    font-size: 30px;
    color:  $light-gray;
    cursor: pointer;
}

.Disabled {
    font-size: 30px;
    color: $dark-gray;
}