@import 'utils/colors.scss';

.Tabs {
    display: flex;
    flex-direction: column;
}

.TabsList {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin-left: 0;

  text-align: center;
  padding: 10px;
  transition: all 0.5s;
  cursor: pointer;

  > li {
    display: inline-block;
    margin-left: 0;
    padding: 10px;
    transition: all 0.5s;
    cursor: pointer;
    width: 100%;
    border-bottom: 2px solid $text-light-gray;

    > * {
        color: $text-light-gray;
    }
  }
}

.MobileTabs {
  flex-direction: column !important;
  text-align: center;
  padding: 0px;

  > li {
    margin: 10px 0px;
  }
}

.Selected {
    border-bottom: 4px solid $default-red !important;
    > * {
        color: $text-primary !important;
    }
}