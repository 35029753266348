@import 'utils/colors.scss';

.OrderOnlineButton {
    background-color: $default-red;
    color: $text-primary;
    height: 48px;
    width: 178px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}