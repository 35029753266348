.Paragraph {
    padding: 8px 0px;
}

.PointsOnly {
    padding-left: 15px;
}

.Subpoints {
    padding-left: 30px;
}