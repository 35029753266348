.footer {
  width: 200px;
  height: 200px;
}

.container {
  width: 200px;
  height: 200px;
  margin-top: 20px;

  &:hover {
    cursor: pointer;
  }
}

.logo {
  background: url("~assets/logo/logo.png") no-repeat;
  background-size: 100% auto;
  background-position: center;
  width: 200px;
  height: 200px;
}

@media (prefers-reduced-motion: no-preference) {
  .logo {
    animation: scaleUpDown 0.7s infinite ease-in-out;
  }
  .container {
    animation: App-logo-spin 20s infinite linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes scaleUpDown {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
