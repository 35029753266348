@import 'utils/colors.scss';
@import 'utils/sizes.scss';

.MainPageSection {
    height:100vh;
    background-color: $main-page-color;
    background-image: url('../../assets/photos/temp/main-background.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: bottom center; 
    background-size: 90% auto;

    @media (max-width: $breakpoint-sm) {
        background-size: 150% auto;
    }


    &__TitleSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        text-align: center;

        position: absolute;
        top: 25vh;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;

    }
}
