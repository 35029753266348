@import 'utils/colors.scss';
@import 'utils/sizes.scss';

.BasketModal {
    padding: 40px;
    text-align: left;

    @media (max-width: $breakpoint-sm) {
        padding: 40px 20px;
    }

    &__Checkbox {
        display: flex;
        flex-direction: row;
        gap: 7px;
        align-items: center;
        padding: 5px 0px;
    }

    &__TCs {
        padding-top: 10px;
    }

    &__Paragraph {
        display: flex;
        flex-direction: row;
        gap: 5px;
    }

    &__ErrorMessage {
        color: $default-red !important;
        font-size: 12px;
    }

    &__Link {
        color: $link;

        &:visited, &:active {
            color: $link-visited;
        }
    }
}
