@import 'utils/colors.scss';

.Radio {
    font-size: 22px;
    color: $text-primary;
    margin-right: 8px;
    cursor: pointer;

    &__RadioButton {
        display: flex;
        flex-direction: row;
        gap: 7px;
        // align-items: center;
        // max-width: 70%;
        text-wrap: balance;
      }
}