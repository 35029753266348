.AuthPage {
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px;

    * > input {
        padding: 10px 20px;
        border-radius: 0px;
    }

    &__Form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
    }

    &__Button {
        padding: 10px 20px;
    }

    &__ErrorMessage {
        color: #EA7E7E !important;
        font-size: 12px;
    }
}